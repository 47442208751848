exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".aTr_dz8-RV60NBvaWd6pC {\n    table-layout: fixed;\n    overflow-wrap: break-word;\n}\n", ""]);

// exports
exports.locals = {
	"fixedTable": "aTr_dz8-RV60NBvaWd6pC"
};